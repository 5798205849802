
import { apiGetLogisticsConfig, apiSetLogisticsConfig } from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Logistics extends Vue {
    form = {
      express_bird: {
        set_meal: 'free',
        app_key: '',
        ebussiness_id: ''
      },
      express_hundred: {
        interface_type: 'enterprise',
        app_key: '',
        customer: ''
      },
      express_type: 'express_bird'
    }

    handleSave () {
      apiSetLogisticsConfig(this.form)
    }

    getLogisticsConfig () {
      apiGetLogisticsConfig({}).then(res => {
        this.form = res
      })
    }

    created () {
      this.getLogisticsConfig()
    }
}
